import React from 'react'
import {Link} from 'gatsby'
import {useTranslation} from "gatsby-plugin-react-i18next";

const LoginForm = () => {
    const {t} = useTranslation();

    return (
        <div className="login-form">
            <h2>{t('Connexion')}</h2>

            <form>
                <div className="form-group">
                    <label>{t('Nomdutilisateurouemail')}</label>
                    <input type="text" className="form-control" placeholder="Email" />
                </div>

                <div className="form-group">
                    <label>{t('VotreMotdePasse')}</label>
                    <input type="Password" className="form-control" placeholder="Password" />
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                        <p>
                            <input type="checkbox" id="test2" />
                            <label>{t('home.LoginForm1')}</label>
                        </p>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                        <Link to="#" className="lost-your-password">
                            {t('home.LoginForm2')}
                        </Link>
                    </div>
                </div>

                <button type="submit">Connexion</button>
            </form>
        </div>
    )
}

export default LoginForm