import React from 'react'
import {useTranslation} from "gatsby-plugin-react-i18next";

const RegisterForm = () => {
    const {t} = useTranslation();

    return (
        <div className="register-form">
            <h2>{t('Sinscrire')}</h2>

            <form>
                <div className="form-group">
                    <label>{t('Nomdutilisateurouemail')}</label>
                    <input type="text" className="form-control" placeholder="Name" />
                </div>

                <div className="form-group">
                    <label>{t('VotreEmail')}</label>
                    <input type="email" className="form-control" placeholder="Email" />
                </div>

                <div className="form-group">
                    <label>{t('VotreMotdePasse')}</label>
                    <input type="password" className="form-control" placeholder="Password" />
                </div>

                <p className="description">{t('home.RegisterForm')}</p>

                <button type="submit">{t('Confirmer')}
                </button>
            </form>
        </div>
    )
}

export default RegisterForm