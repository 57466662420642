import React, { useState } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

const LanguageBox = ({}) => {
	const { languages, changeLanguage, i18n } = useI18next();

	// set language state
	const [values, setLanguage] = useState({
		language: i18n.language,
	});

	// set language
	const language = values.language;

	// change language
	function handleChange(e) {
		const value = e.target.value;
		changeLanguage(value);
		setLanguage((oldValues) => ({
			...oldValues,
			language: value,
		}));
	}

	return (
		<select
			name="language"
			defaultValue={language}
			onChange={(e) => handleChange(e)}
			className="selectBox"
		>
			{languages.map((lng) => (
				<option key={lng} value={lng}>
					{lng.toUpperCase()}
				</option>
			))}
		</select>
	);
};

export default LanguageBox;
