import React from "react";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../utils/recoil-atoms";
import { Link } from "gatsby-plugin-react-i18next";
import logo from "../../assets/images/logo.png";
import LanguageBox from "./LanguageBox";
import { useTranslation } from "gatsby-plugin-react-i18next";

const NB3en = () => {
	const { t } = useTranslation();

	const [collapsed, setCollapsed] = useRecoilState(collapsedState);

	const toggleNavbar = () => {
		setCollapsed(!collapsed);
	};

	React.useEffect(() => {
		let elementId = document.getElementById("navbar");
		document.addEventListener("scroll", () => {
			if (window.scrollY > 170) {
				elementId.classList.add("is-sticky");
			} else {
				elementId.classList.remove("is-sticky");
			}
		});
		window.scrollTo(0, 0);
	});

	const classOne = collapsed
		? "collapse navbar-collapse"
		: "collapse navbar-collapse show";
	const classTwo = collapsed
		? "navbar-toggler navbar-toggler-right collapsed"
		: "navbar-toggler navbar-toggler-right";

	return (
		<React.Fragment>
			<div id="navbar" className="navbar-area">
				<div className="tarn-nav">
					<div className="container">
						<nav className="navbar navbar-expand-lg navbar-light">
							<Link
								to="/"
								onClick={() => setCollapsed(true)}
								className="navbar-brand"
							>
								<img src={logo} alt="logo" />
							</Link>

							<button
								onClick={toggleNavbar}
								className={classTwo}
								type="button"
								data-toggle="collapse"
								data-target="#navbarSupportedContent"
								aria-controls="navbarSupportedContent"
								aria-expanded="false"
								aria-label="Toggle navigation"
							>
								<span className="icon-bar top-bar"></span>
								<span className="icon-bar middle-bar"></span>
								<span className="icon-bar bottom-bar"></span>
							</button>

							<div
								className={classOne}
								id="navbarSupportedContent"
							>
								<ul className="navbar-nav">
									<LanguageBox />

									<li className="nav-item">
										<Link
											to="#"
											activeClassName="active"
											onClick={(e) => e.preventDefault()}
											className="nav-link"
										>
											{t("Produits")}{" "}
											<i className="bx bx-chevron-down"></i>
										</Link>

										<ul className="dropdown-menu">
											<li className="nav-item">
												<Link
													to="/talent-management-system"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("CompanyBranding")}
													<p>{t("home.NB3enp1")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/recruiting-system"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("ExpérienceCandidat")}
													<p>{t("home.NB3enp2")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/employee-profile-branding-system"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("PersonalBranding")}
													<p>{t("home.NB3enp3")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/hr-recruitment-solution"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("Recrutements")}
													<p>{t("home.NB3enp4")}</p>
												</Link>
											</li>
										</ul>
									</li>

									<li className="nav-item">
										<Link
											to="#"
											activeClassName="active"
											onClick={(e) => e.preventDefault()}
											className="nav-link"
										>
											{t("Solutions")}
											<i className="bx bx-chevron-down"></i>
										</Link>

										<ul className="dropdown-menu">
											<li className="nav-item">
												<Link
													to="/talent-acquisition-strategy"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("TalentAcquisition")}
													<p>{t("home.NB3enp5")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/employee-reference-solutions"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t(
														"LesRéférencesdePairàPair"
													)}
													<p>{t("home.NB3enp6")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/profile-social-sharing"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("CROWLRSocialShare")}
													<p>{t("home.NB3enp7")}</p>

													{/*
                                                        Less about you, more about what you can do!
                                                        */}
												</Link>
											</li>
											<li className="nav-item">
												<Link
													to="/cooptation-recruitment-solutions"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("ProgrammedeCooptation")}
													<p>{t("home.NB3enp8")}</p>
												</Link>
											</li>
										</ul>
									</li>

									<li className="nav-item">
										<Link
											to="#"
											activeClassName="active"
											onClick={(e) => e.preventDefault()}
											className="nav-link"
										>
											{t("Services")}
											<i className="bx bx-chevron-down"></i>
										</Link>

										<ul className="dropdown-menu">
											<li className="nav-item">
												<Link
													to="/services"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("NosServices")}
													<p>{t("home.NB3enp9")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/mobility-and-decision-support-solutions"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("Mobilité")}
													<p>{t("home.NB3enp10")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/interactive-job-candidacy-and-employability"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("BenchmarkInteractif")}
													<p>{t("home.NB3enp11")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/selfpromotion-and-talent-community"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t(
														"VisibilitéetAutopromotion"
													)}
													<p>{t("home.NB3enp12")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<Link
													to="/prequalification-rgpd-and-anonymous-candidacy"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("Préqualification")}
													<p>{t("home.NB3enp13")}</p>
												</Link>
											</li>
										</ul>
									</li>

									<li className="nav-item">
										<Link
											to="#"
											activeClassName="active"
											onClick={(e) => e.preventDefault()}
											className="nav-link"
										>
											{t("Apropos")}{" "}
											<i className="bx bx-chevron-down"></i>
										</Link>

										<ul className="dropdown-menu">
											<li className="nav-item">
												<Link
													to="/about-us"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("CROWLR")}
													<p>{t("home.NB3enp14")}</p>
												</Link>
											</li>

											<li className="nav-item">
												<a
													href="https://admin.crowlr.com"
													className="nav-link"
													target="_blank"
													rel="noreferrer"
												>
													{t("Portail Carrière")}
													<p>{t("home.NB3enp141")}</p>												</a>
											</li>

											<li className="nav-item">
												<Link
													to="/contact"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("Contact")}
												</Link>
											</li>
											<li className="nav-item">
												<Link
													to="/hrm-eco-system"
													activeClassName="active"
													onClick={() =>
														setCollapsed(true)
													}
													className="nav-link"
												>
													{t("Écosystème")}
													<p>{t("home.NB3enp15")}</p>
												</Link>
											</li>
										</ul>
									</li>

									<li className="nav-item"></li>
									<li className="nav-item"></li>
									<li className="nav-item"></li>
									<li className="nav-item"></li>
									<li className="nav-item"></li>

									<li className="nav-item">
										<a
											href="https://admin.crowlr.com/careers/jobs"
											className="nav-link"
											target="_blank"
											rel="noreferrer"
										>
											{t("JobBoard")}
										</a>
									</li>
									<li className="nav-item">
										<Link
											to="/blog"
											activeClassName="active"
											//onClick={e => e.preventDefault()}
											className="nav-link"
										>
											{t("Blog")}
										</Link>
									</li>
									<li className="nav-item">
										<a
											href="https://admin.crowlr.com/register"
											//onClick={e => e.preventDefault()}
											className="nav-link"
											target="_blank"
											rel="noreferrer"
										>
											{t("SeConnecter")}
										</a>
									</li>
								</ul>

								{/*
                                <div className="others-option d-flex align-items-center">
                                    <div className="option-item">
                                        <form className="search-box">
                                            <input type="text" className="input-search" placeholder="Search for anything" />
                                            <button type="submit">
                                                <i className="flaticon-loupe"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            */}
							</div>
						</nav>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default NB3en;
