import React from 'react'
import Layout from "../components/App/Layout"
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import LoginForm from '../components/ProfileAuthentication.js/LoginForm'
import RegisterForm from '../components/ProfileAuthentication.js/RegisterForm'
import {useTranslation} from "gatsby-plugin-react-i18next";

const ProfileAuthentication = ({location}) => {
    const {t} = useTranslation();

    return (
        <Layout path={location.pathname}>
            <NavbarThree />


            <section className="profile-authentication-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <LoginForm />
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <RegisterForm />
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </Layout>
    )
}

export default ProfileAuthentication;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;